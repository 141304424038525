import React from "react";
import { NFT_LINKS } from "@/consts";

const FooterLinks = () => (
  <div className="text-white text-regular text-center flex flex-col mx-40 font-avantGarde">
    <p>NFTs avaialble on</p>
    <ul className="flex items-center justify-center">
      <li>
        <a
          href={NFT_LINKS.FOUNDATION}
          target="_blank"
          rel="noreferrer"
          className="text-white"
        >
          Foundation
        </a>
      </li>
      <li className="mx-4">|</li>
      <li>
        <a
          href={NFT_LINKS.MUSEE}
          target="_blank"
          rel="noreferrer"
          className="text-white"
        >
          Musee
        </a>
      </li>
      <li className="mx-4">|</li>
      <li>
        <a
          href={NFT_LINKS.OPENSEA}
          target="_blank"
          rel="noreferrer"
          className="text-white"
        >
          OpenSea
        </a>
      </li>
    </ul>
  </div>
);

export default FooterLinks;
