import React from "react";
import seoImage from "@/assets/seo.jpg";
import { Helmet } from "react-helmet";

const SEO_TITLE = "Kamasutra Lovers";
const SEO_DESCRIPTION =
  "Voyeur Intime is the blockchain driven digital art magazine of the Kama Sutra Lovers - the Parisian based art duo for Sound and Vision.";

const Seo = ({ title }) => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title={title || SEO_TITLE}
        defaultTitle={SEO_TITLE}
        titleTemplate={`%s | ${SEO_TITLE}`}
        meta={[
          {
            name: `description`,
            content: SEO_DESCRIPTION,
          },
          {
            property: `og:title`,
            content: SEO_TITLE,
          },
          {
            property: `og:description`,
            content: SEO_DESCRIPTION,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: "test",
          },
          {
            name: `twitter:title`,
            content: SEO_TITLE,
          },
          {
            name: `twitter:description`,
            content: SEO_DESCRIPTION,
          },
          {
            property: `og:image`,
            content: seoImage,
          },
          {
            property: `twitter:image`,
            content: seoImage,
          },
        ]}
      />
    </>
  );
};

export default Seo;
