import React, { useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { MobileMenu } from "@/components/MobileMenu/MobileMenu";
import { Hamburger } from "@/components/Hamburger/Hamburger";

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenuDisplay = () => setMenuOpen(!isMenuOpen);

  return (
    <>
      <MobileMenu isOpen={isMenuOpen} toggleMenuDisplay={toggleMenuDisplay} />
      <header className="w-full pt-[25px] px-u">
        <div className="max-w-[1350px] w-full mx-auto ">
          <div className="hidden md:flex flex-row justify-between items-center">
            <div className="items-center justify-start w-80 flex">
              <Hamburger
                isOpen={isMenuOpen}
                toggleMenuDisplay={toggleMenuDisplay}
              />
            </div>
            <Link to="/">
              <StaticImage
                src="../../assets/logo@2.webp"
                alt="logo"
                className="w-[70px] h-[70px]"
              />
            </Link>
            <div className="pb-4 self-end">
              <StaticImage
                src="../../assets/intime.png"
                alt="logo"
                className="w-80"
              />
            </div>
          </div>
          <div className="md:hidden mx-auto w-full flex justify-between items-center">
            <Hamburger
              isOpen={isMenuOpen}
              toggleMenuDisplay={toggleMenuDisplay}
            />
            <Link to="/">
              <StaticImage
                src="../../assets/logo-mobile.webp"
                alt="logo"
                className="max-w-[140px]"
              />
            </Link>
            <div />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
