export const KSL_LINKS = {
  WEBSITE: "https://kamasutralovers.com",
  INSTAGRAM: "http://www.instagram.com/kamasutralovers/",
  YOUTUBE: "http://www.youtube.com/kamasutralovers",
  TWITTER: "https://twitter.com/KamaSutraLovers",
};

export const VI_LINKS = {
  INSTAGRAM: "http://www.instagram.com/voyeurintime/",
  TWITTER: "https://twitter.com/voyeurintime",
};

export const NFT_LINKS = {
  FOUNDATION: "https://foundation.app/@kamasutralovers",
  MUSEE:
    "https://www.musee.art/user-profile/0x190f08E595F36d1BB25C644C5F15B42210b84d27",
  OPENSEA: "https://opensea.io/kamasutralovers",
};
