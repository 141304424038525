import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import FooterLinks from "@/components/FooterLinks/FooterLinks";
import { KSL_LINKS, VI_LINKS } from "@/consts";

const Footer = () => {
  return (
    <footer className="bg-color-dark-80 flex flex-col pb-4 pt-20">
      <div className="max-w-[1370px] mx-auto w-full">
        <div className="md:hidden">
          <FooterLinks />
        </div>
        <div className="flex items-center justify-center mt-20 mb-u">
          <div className="flex flex-col items-center justify-center max-w-[140px] w-full mr-8">
            <StaticImage
              src="../../assets/logo.webp"
              alt="logo"
              className="max-w-[40px]"
            />
            <ul className="flex items-center justify-between mt-4">
              <li className="max-w-[20px] mx-[7.5px]">
                <a href={VI_LINKS.INSTAGRAM} target="_blank" rel="noreferrer">
                  <StaticImage
                    src="../../assets/instagram.webp"
                    alt="instagram"
                  />
                </a>
              </li>
              <li className="max-w-[20px] mx-[7.5px]">
                <a href={VI_LINKS.TWITTER} target="_blank" rel="noreferrer">
                  <StaticImage src="../../assets/twitter.webp" alt="twitter" />
                </a>
              </li>
            </ul>
          </div>
          <div className="hidden md:block">
            <FooterLinks />
          </div>
          <div className="flex flex-col items-center max-w-[140px] ml-8">
            <StaticImage
              src="../../assets/ksllogo@2.webp"
              alt="SL Logo"
              className="max-w-[55px]"
            />
            <ul className="flex items-center justify-between mt-4">
              <li className="max-w-[20px] mx-[7.5px]">
                <a href={KSL_LINKS.WEBSITE} target="_blank" rel="noreferrer">
                  <StaticImage src="../../assets/web.webp" alt="web" />
                </a>
              </li>
              <li className="max-w-[20px] mx-[7.5px]">
                <a href={KSL_LINKS.INSTAGRAM} target="_blank" rel="noreferrer">
                  <StaticImage
                    src="../../assets/instagram.webp"
                    alt="instagram"
                  />
                </a>
              </li>
              <li className="max-w-[20px] mx-[7.5px]">
                <a href={KSL_LINKS.YOUTUBE} target="_blank" rel="noreferrer">
                  <StaticImage src="../../assets/youtube.webp" alt="youtube" />
                </a>
              </li>
              <li className="max-w-[20px] mx-[7.5px]">
                <a href={KSL_LINKS.TWITTER} target="_blank" rel="noreferrer">
                  <StaticImage src="../../assets/twitter.webp" alt="twitter" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex justify-center md:justify-between font-avantGarde">
          <p className="text-white text-light max-w-[140px] md:max-w-full md:w-auto whitespace-nowrap w-full mr-8 md:mr-0 text-center md:text-left">
            {" "}
            &copy; 2021 Voyeur Intime{" "}
          </p>
          <ul className="uppercase flex items-center justify-center text-light text-white ml-8 md:ml-0 max-w-[140px] w-full md:max-w-full">
            <li>
              <Link className="text-white text-light" to="/about">
                About
              </Link>
            </li>
            <li className="mx-2">|</li>
            <li>
              <AnchorLink className="text-white text-light" to="/about#contact">
                Contact
              </AnchorLink>
            </li>
          </ul>
          <div className="w-48 hidden md:block" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
