import React from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage } from "gatsby-plugin-image";
import { Hamburger } from "@/components/Hamburger/Hamburger";
import { windowGlobal } from "@/helpers/isWindowGlobal";
import { KSL_LINKS, VI_LINKS, NFT_LINKS } from "@/consts";

export const MobileMenu = ({ isOpen, toggleMenuDisplay }) => {
  const isHomepage = windowGlobal && windowGlobal.location.pathname === "/";

  return (
    <div
      className={`h-screen sticky top-0 z-10 bg-color-dark-100 flex-col  ${
        isOpen ? "flex" : "hidden"
      }`}
    >
      <div className={`pt-20 w-full mx-auto ${isOpen ? "block" : "hidden"}`}>
        <div className="max-w-[1350px] px-u xl:px-0 mx-auto ">
          <Hamburger isOpen={isOpen} toggleMenuDisplay={toggleMenuDisplay} />
        </div>
      </div>
      <div
        className={`overflow-auto  w-full flex flex-col items-center font-avantGarde text-regular text-white `}
      >
        {!isHomepage && (
          <Link to="/" className="text-regular text-white mb-u font-medium">
            <button className="hover:underline" onClick={toggleMenuDisplay}>
              HOME
            </button>
          </Link>
        )}
        <AnchorLink
          to="/about"
          className="text-regular text-white mb-u font-medium"
        >
          <button className="hover:underline" onClick={toggleMenuDisplay}>
            ABOUT
          </button>
        </AnchorLink>
        <AnchorLink
          to="/about#contact"
          className="text-white mb-u font-medium"
          onClick={toggleMenuDisplay}
        >
          <button onClick={toggleMenuDisplay} className="hover:underline">
            CONTACT
          </button>
        </AnchorLink>
        <h2 className="text-regular font-medium mb-4">NFTs</h2>
        <a href={NFT_LINKS.FOUNDATION} className="text-white hover:underline">
          Foundation
        </a>
        <a href={NFT_LINKS.MUSEE} className="text-white hover:underline">
          Musee
        </a>
        <a href={NFT_LINKS.OPENSEA} className="text-white mb-u hover:underline">
          OpenSea
        </a>
        <h2 className="text-regular mb-u font-medium">SOCIALS</h2>
        <div className="flex flex-col items-center justify-center max-w-[140px] w-full">
          <StaticImage
            src="../../assets/logo.webp"
            alt="logo"
            className="max-w-[40px]"
          />
          <ul className="flex items-center justify-between mt-4 mb-u">
            <li className="max-w-[20px] mx-[7.5px]">
              <a
                href={VI_LINKS.INSTAGRAM}
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center"
              >
                <StaticImage
                  src="../../assets/instagram.webp"
                  alt="instagram"
                />
              </a>
            </li>
            <li className="max-w-[20px] mx-[7.5px]">
              <a
                href={VI_LINKS.TWITTER}
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center"
              >
                <StaticImage src="../../assets/twitter.webp" alt="twitter" />
              </a>
            </li>
          </ul>
        </div>
        <div className="flex flex-col items-center max-w-[140px]">
          <StaticImage
            src="../../assets/ksllogo@2.webp"
            alt="SL Logo"
            className="max-w-[55px]"
          />
          <ul className="flex items-center justify-between mt-4">
            <li className="max-w-[20px] mx-[7.5px]">
              <a
                href={KSL_LINKS.WEBSITE}
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center"
              >
                <StaticImage src="../../assets/web.webp" alt="web" />
              </a>
            </li>
            <li className="max-w-[20px] mx-[7.5px]">
              <a
                href={KSL_LINKS.INSTAGRAM}
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center"
              >
                <StaticImage
                  src="../../assets/instagram.webp"
                  alt="instagram"
                />
              </a>
            </li>
            <li className="max-w-[20px] mx-[7.5px]">
              <a
                href={KSL_LINKS.YOUTUBE}
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center"
              >
                <StaticImage src="../../assets/youtube.webp" alt="youtube" />
              </a>
            </li>
            <li className="max-w-[20px] mx-[7.5px]">
              <a
                href={KSL_LINKS.TWITTER}
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center"
              >
                <StaticImage src="../../assets/twitter.webp" alt="twitter" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
