import React from "react";
import clsx from "clsx";

export const Hamburger = ({ isOpen, toggleMenuDisplay }) => {
  return (
    <button onClick={toggleMenuDisplay} className="h-[25px] w-[28px]">
      <div
        className={clsx(
          "relative outline-none duration-200 h-[2px] w-full bg-white rounded-full before:block before:h-[2px] before:w-full before:bg-white before:rounded-full before:absolute before:transform before:translate-y-4 after:block after:h-[2px] after:w-full after:bg-white after:rounded-full after:absolute after:transform after:-translate-y-4 before:duration-200 after:duration-200",
          isOpen &&
            "before:-rotate-90 rotate-45 before:translate-y-0 after:translate-y-0  after:opacity-0 translate-y-0 before:duration-200 after:duration-200 "
        )}
      />
    </button>
  );
};

export default Hamburger;
