import React from "react";

import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer";
import Seo from "@/components/Seo/Seo";
import "@/styles/base.css";

const Layout = ({ title, children }) => {
  return (
    <>
      <Header />
      <Seo title={title} />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
